export * from './category.service';
import { CategoryService } from './category.service';
export * from './document.service';
import { DocumentService } from './document.service';
export * from './documentClass.service';
import { DocumentClassService } from './documentClass.service';
export * from './equipment.service';
import { EquipmentService } from './equipment.service';
export * from './graphicsArchive.service';
import { GraphicsArchiveService } from './graphicsArchive.service';
export * from './internal.service';
import { InternalService } from './internal.service';
export * from './search.service';
import { SearchService } from './search.service';
export const APIS = [CategoryService, DocumentService, DocumentClassService, EquipmentService, GraphicsArchiveService, InternalService, SearchService];
