import { SuspensionMode } from '../generated';
import { SideStabilizer } from '../generated/model/sideStabilizer';

export class Constants {
  /**
   * Maximum value that can be exported to the PAW file. Shared as constant so that e.g. the web
   *  application can already do checks on the client.
   */
  public static get MAX_VALUE_FOR_EXPORTED_NUMBERS() {
    return 65534;
  }
  /**
   * This value comes from SAP and is used very often there
   */
  public static get OHNE() {
    return 'OHNE';
  }
  /**
   * This value comes from SAP (epsilon) and is used very often there (equivalent to OHNE)
   */
  public static get WO() {
    return 'WO';
  }
  /**
   * This value represents an epsilon crane eqart
   */
  public static get EPSILON() {
    return '010';
  }
  /*
   * This value represents an railway crane eqart
   */
  public static get RAILWAY() {
    return '002';
  }
  /**
   * This value represents a sap (default) crane eqart
   */
  public static get SAP() {
    return '001';
  }
  /**
   * This value represents a Paltronic 40 Crane (Sap Configuration "PAL" value)
   */
  public static get PT040() {
    return 'PT040';
  }
  /**
   * Filter map to replace ABST values (cut of Y at the end), PACWEB-74, PACWEB-151
   */
  public static get filterMapABSTY() {
    return [];
  }

  public static get ON_H1() {
    return 5;
  }

  public static get ON_H3() {
    return 7;
  }

  public static get LCA() {
    return 'LCA';
  }

  public static get LCA06() {
    return 'LCA06';
  }

  public static get TILT_DEGREES() {
    return [4, 5, 6, 7, 8];
  }

  public static get TILT_DEGREE_DEFAULT() {
    return 5;
  }

  public static get TipPointDict() {
    const dict: { [id: string]: number } = {};
    dict[1 + SuspensionMode.Air] = Constants.ON_H1;
    dict[1 + SuspensionMode.Leaf] = Constants.ON_H1;
    dict[2 + SuspensionMode.Air] = Constants.ON_H1;
    dict[2 + SuspensionMode.Leaf] = Constants.ON_H1;
    dict[3 + SuspensionMode.Air] = Constants.ON_H3;
    dict[3 + SuspensionMode.Leaf] = Constants.ON_H3;
    dict[4 + SuspensionMode.Air] = Constants.ON_H3;
    dict[4 + SuspensionMode.Leaf] = Constants.ON_H3;
    return dict;
  }

  public static get ScaleFactor() {
    return 0.188976137139952;
  }

  public static get NullPointX() {
    return 547.85385;
  }

  public static get NullPointY() {
    return 875;
  }

  public static get FixedAxleHeight() {
    return 372.10916;
  }

  public static get AdditionalOffsetRear() {
    return 56.41315645901847;
  }

  public static get MeasurementOffset() {
    return 70;
  }

  public static get DefaultSideStabilizer() {
    return <SideStabilizer>{
      distance: 0,
      leftInnerX: 0,
      leftOuterX: 0,
      rightInnerX: 0,
      rightOuterX: 0,
      leftInnerY: 0,
      leftOuterY: 0,
      rightInnerY: 0,
      rightOuterY: 0,
    };
  }

  public static get ExtraStabilizer1() {
    return 'extraStabilizer1';
  }

  public static get ExtraStabilizer2() {
    return 'extraStabilizer2';
  }

  public static get Carrier() {
    return 'Carrier';
  }

  public static get Trailer() {
    return 'Trailer';
  }

  // <ng-container *ngSwitchCase="0">{{
  //   'sso.code_validation.already_configured' | translate
  // }}</ng-container>
  // <ng-container *ngSwitchCase="1">{{
  //   'sso.code_validation.configurable' | translate
  // }}</ng-container>
  // <ng-container *ngSwitchCase="2">{{
  //   'sso.code_validation.configurable_with_charge' | translate
  // }}</ng-container>
  // <ng-container *ngSwitchCase="3">{{
  //   'sso.code_validation.not_configurable' | translate
  // }}</ng-container>
  // <ng-container *ngSwitchDefault>{{
  //   'sso.code_validation.error' | translate
  // }}</ng-container>

  public static get AlreadyConfigured() {
    return 0;
  }

  public static get Configurable() {
    return 1;
  }

  public static get NotConfigurable() {
    return 3;
  }

  public static get OrderCreated() {
    return 0;
  }

  public static get ActivateSemiTrailer() {
    return 'ACTIVATESEMITRAILER';
  }

  public static get Analog() {
    return 'ANALOG';
  }

  public static get Digital() {
    return 'DIGITAL';
  }

  public static get Crane2002() {
    return 'S601-SL-A';
  }

  public static get AbstR() {
    return 'R';
  }

  public static get CalculationDefaults() {
    return {
      stab1Distance: 4000,
      stab2Distance: 7500,
      stabInnerY: 1500,
      frontStabDistance: -1500,
      rearStabDistance: 8000,
      rearStabXOffExtended: 1000,
      cylinderDistance: 800,
      craneStabDistance: 0,
      distanceFront: 2000,
      distanceRear: 2000,
      rearAxleDistance: 4000,
      standardAxleDistance: 1250,
      frontWeight: 6220,
      rearWeight: 4970,
      loadX: 1000,
      loadWidth: 4500,
      loadY: 1250,
      loadHeight: 2500,
      loadWeight: 5000,
      extendableOffset: 500,
      semiTrailerX: 4000,
    };
  }
}

export const PacwebFileFormats = {
  PAW: { format: 'PAW', name: 'Pacweb file' },
  DEBUG: { format: 'mdb', name: 'Pacweb DEBUG file' },
  CALC: { format: 'xls', name: 'Pacweb EXCEL file' },
  SCREENSHOT: { format: 'png', name: 'Pacweb screenshot' },
};
