import { ActionReducerMap } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CranesState, cranesReducer, CranesEffects } from './cranes';

export interface PacwebState {
  cranes: CranesState;
}

export const pacwebReducers: ActionReducerMap<PacwebState, any> = {
  cranes: cranesReducer,
};

export const pacwebEffects = [EffectsModule.forFeature([CranesEffects])];
