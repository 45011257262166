/* eslint-disable max-lines */
import { Injectable, inject } from '@angular/core';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import { UserService } from '@features/auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PacWebService as PacwebSsoService } from '@paldesk/shared-lib/data-access/sso-generated';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { PacwebState } from '..';
import {
  CalculationService,
  CreateNewConstructionCommand,
  StorageService,
} from '../../shared/generated';
import { PacwebService } from '../../shared/services/pacweb.service';
import { Constants } from '../../shared/utils/constants';
import { CranesActions } from './cranes.actions';
import { CranesSelectors } from './cranes.selectors';

@Injectable()
export class CranesEffects {
  private actions$ = inject(Actions);
  private store: Store<PacwebState> = inject(Store);
  /**
   * New calculation pop-up
   */
  loadEquipments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.LoadEquipments),
      map((action) => action.payload),
      switchMap((equipmentNr: string) =>
        this.calculationService.findEquipment(equipmentNr).pipe(
          map((data) => CranesActions.LoadEquipmentsSuccess({ payload: data })),
          catchError(() => of(CranesActions.LoadEquipmentsError())),
        ),
      ),
    ),
  );

  loadCalculationInfos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.LoadCalculationInfos),
      map((action) => action.payload),
      switchMap((filter) =>
        this.calculationService
          .getConstructions(
            filter.searchTerm,
            filter.pageIndex,
            filter.pageSize,
          )
          .pipe(
            map((data) =>
              CranesActions.LoadCalculationInfosSuccess({ payload: data }),
            ),
            catchError(() => of(CranesActions.LoadCalculationInfosError())),
          ),
      ),
    ),
  );

  /**
   * Calculation screen
   */
  loadNewCalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.LoadNewCalculation),
      withLatestFrom(
        this.store.select(CranesSelectors.selectedEquipment),
        this.store.select(CranesSelectors.selectedCraneDataStatus),
      ),
      switchMap(([, /*don't need*/ equipment, craneDataStatus]) =>
        this.calculationService
          .createNewConstruction(<CreateNewConstructionCommand>{
            equipment: equipment,
            craneDataStatus: craneDataStatus,
            asConversion: false,
          })
          .pipe(
            map((data) =>
              CranesActions.LoadNewCalculationSuccess({ payload: data }),
            ),
            catchError(() => of(CranesActions.LoadCalculationError())),
          ),
      ),
    ),
  );

  loadCalculationByDebitorId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.LoadCalculationByDebitorId),
      map((action) => action),
      switchMap((calculationInfo) =>
        this.calculationService.open(calculationInfo.calculationId).pipe(
          map((data) =>
            CranesActions.LoadExistingCalculationSuccess({ payload: data }),
          ),
          catchError(() => of(CranesActions.LoadCalculationError())),
        ),
      ),
    ),
  );

  loadCalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.LoadCalculation),
      withLatestFrom(this.store.select(CranesSelectors.selectedCalculationId)),
      switchMap(([, /*don't need*/ selectedCalculationInfoId]) => {
        if (selectedCalculationInfoId)
          return this.calculationService.open(selectedCalculationInfoId).pipe(
            map((data) =>
              CranesActions.LoadExistingCalculationSuccess({ payload: data }),
            ),
            catchError(() => of(CranesActions.LoadCalculationError())),
          );
        else return of(CranesActions.LoadCalculationError());
      }),
    ),
  );

  uploadCalculationFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.UploadCalculationFile),
      map((action) => action.payload),
      switchMap((file) =>
        this.storageService.handleImportConstruction(file).pipe(
          map((data) =>
            CranesActions.UploadCalculationFileSuccess({ payload: data }),
          ),
          catchError(() => of(CranesActions.UploadCalculationFileError())),
        ),
      ),
    ),
  );

  /**
   * Truck
   */
  loadTruckManufacturers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.LoadTruckManufacturers),
      switchMap(() =>
        this.calculationService.getManufacturers().pipe(
          map((data) =>
            CranesActions.LoadTruckManufacturersSuccess({ payload: data }),
          ),
          catchError(() => of(CranesActions.LoadTruckManufacturersError())),
        ),
      ),
    ),
  );

  /**
   * Crane
   */
  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.LoadCategories),
      switchMap(() =>
        this.calculationService
          .getCategories(this.userService.userContext.lang)
          .pipe(
            map((data) =>
              CranesActions.LoadCategoriesSuccess({ payload: data }),
            ),
            catchError(() => of(CranesActions.LoadCategoriesError())),
          ),
      ),
    ),
  );

  loadEpsilonCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.LoadEpsilonCategories),
      switchMap(() =>
        this.calculationService
          .getEpsilonCategories(this.userService.userContext.lang)
          .pipe(
            map((data) =>
              CranesActions.LoadEpsilonCategoriesSuccess({ payload: data }),
            ),
            catchError(() => of(CranesActions.LoadEpsilonCategoriesError())),
          ),
      ),
    ),
  );

  loadStabilizers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.LoadStabilizers),
      switchMap(() =>
        this.calculationService.getGlobalConfig().pipe(
          map((data) =>
            CranesActions.LoadStabilizersSuccess({ payload: data }),
          ),
          catchError(() => of(CranesActions.LoadStabilizersError())),
        ),
      ),
    ),
  );

  /**
   * Calculation
   */
  updateCalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.UpdateCalculation),
      map((action) => action.payload),
      switchMap((construction) =>
        this.calculationService.updateCraneData(construction).pipe(
          map((data) =>
            CranesActions.UpdateCalculationSuccess({ payload: data }),
          ),
          catchError(() => {
            this.snackbar.queue(
              this.translate.instant('pacweb.errors.update'),
              {
                type: DsSnackbarType.Error,
              },
            );

            return of(CranesActions.UpdateCalculationError());
          }),
        ),
      ),
    ),
  );

  updateStabilizerData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.UpdateStabilizerData),
      map((action) => action),
      switchMap((constructionWithStabNr) =>
        this.calculationService
          .updateSideStabilizerData(constructionWithStabNr)
          .pipe(
            map((data) =>
              CranesActions.UpdateStabilizerDataSuccess({ payload: data }),
            ),
            catchError(() => {
              this.snackbar.queue(
                this.translate.instant('pacweb.errors.update_stabilizer'),
                {
                  type: DsSnackbarType.Error,
                },
              );
              return of(CranesActions.UpdateStabilizerDataError());
            }),
          ),
      ),
    ),
  );

  validateSSO$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.ValidateSSO),
      map((action) => action),
      switchMap((constructionWithSSOValues) =>
        this.ssoService
          .validateSoftwareOption(
            constructionWithSSOValues.construction.equipmentNumber,
            constructionWithSSOValues.option,
            constructionWithSSOValues.optionValue,
          )
          .pipe(
            map((data) => {
              if (data.status === Constants.NotConfigurable)
                return CranesActions.ValidateSSOError();
              else return CranesActions.ValidateSSOSuccess({ payload: data });
            }),
            catchError(() => of(CranesActions.ValidateSSOError())),
          ),
      ),
    ),
  );

  orderSSO$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.OrderSSO),
      map((action) => action),
      switchMap((payload) =>
        this.ssoService.orderSoftwareOption(payload.optionRequest).pipe(
          map((data) => CranesActions.OrderSSOSuccess({ payload: data })),
          catchError(() => of(CranesActions.OrderSSOError())),
        ),
      ),
    ),
  );

  loadExportFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.LoadExportFile),
      withLatestFrom(this.store.select(CranesSelectors.calculation)),
      switchMap(([, /*don't need*/ calculation]) => {
        if (calculation)
          return this.storageService
            .handleDownload(calculation.construction.uniqueId || '')
            .pipe(
              map(
                (fileLink) =>
                  CranesActions.LoadExportFileSuccess({ payload: fileLink }),
                catchError(() => of(CranesActions.LoadExportFileError())),
              ),
            );
        else return of(CranesActions.LoadExportFileError());
      }),
    ),
  );

  loadExportFileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CranesActions.LoadExportFileSuccess),
        map((action) => action.payload),
        switchMap((downloadLink) => {
          if (downloadLink.downloadUri)
            return this.pacwebService
              .downloadFile(downloadLink.downloadUri)
              .pipe(
                tap(
                  (blob) => {
                    saveAs(blob, `construction.xml`);
                  },
                  catchError(() => of(CranesActions.LoadExportFileError())),
                ),
              );
          else return of(CranesActions.LoadExportFileError());
        }),
      ),
    { dispatch: false },
  );

  /**
   * Load calculations archive
   */
  loadCalculationsArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.LoadArchive),
      map((action) => action),
      switchMap((loadArchiveIds) =>
        this.calculationService.getCalculations(loadArchiveIds.uniqueId).pipe(
          map((data) => CranesActions.LoadArchiveSuccess({ payload: data })),
          catchError(() => of(CranesActions.LoadArchiveError())),
        ),
      ),
    ),
  );

  /**
   * Save construction
   */
  saveConstruction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.SaveConstruction),
      map((action) => action.payload),
      switchMap((construction) =>
        this.calculationService.save(construction).pipe(
          map((data) =>
            CranesActions.SaveConstructionSuccess({ payload: data }),
          ),
          catchError(() => {
            this.snackbar.queue(this.translate.instant('pacweb.errors.save'), {
              type: DsSnackbarType.Error,
            });
            return of(CranesActions.SaveConstructionError());
          }),
        ),
      ),
    ),
  );

  requestCalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.RequestCalculation),
      map((action) => action.payload),
      withLatestFrom(this.store.select(CranesSelectors.calculation)),
      switchMap(([file, calculation]) => {
        if (calculation?.construction.uniqueId)
          return this.storageService
            .handleUploadImage(calculation.construction.uniqueId || '', file)
            .pipe(
              withLatestFrom(
                this.store.select(CranesSelectors.calculationRequest),
              ),
              switchMap(([, /*don't need*/ request]) =>
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.calculationService.calculate(request!).pipe(
                  map((data) =>
                    CranesActions.RequestCalculationSuccess({ payload: data }),
                  ),
                  catchError(() => {
                    this.snackbar.queue(
                      this.translate.instant('pacweb.errors.calculation'),
                      {
                        type: DsSnackbarType.Error,
                      },
                    );
                    return of(CranesActions.RequestCalculationError());
                  }),
                ),
              ),
              catchError(() => {
                this.snackbar.queue(
                  this.translate.instant('pacweb.errors.upload'),
                  {
                    type: DsSnackbarType.Error,
                  },
                );
                return of(CranesActions.RequestCalculationError());
              }),
            );
        else return of(CranesActions.RequestCalculationError());
      }),
    ),
  );

  downloadFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CranesActions.DownloadFile),
        map((action) => action),
        withLatestFrom(this.store.select(CranesSelectors.calculation)),
        switchMap(([urlAndFormat, calculation]) =>
          this.pacwebService.downloadFile(urlAndFormat.url).pipe(
            tap((blob) => {
              if (urlAndFormat && calculation)
                saveAs(
                  blob,
                  `${
                    calculation.construction.eqart !== Constants.EPSILON
                      ? calculation.construction.equipmentNumber
                      : calculation.construction.serialNumber
                  }.${urlAndFormat.format}`,
                );
              else if (urlAndFormat && urlAndFormat.fileName)
                saveAs(blob, `${urlAndFormat.fileName}.${urlAndFormat.format}`);
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  deleteCalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.DeleteCalculation),
      map((action) => action.payload),
      withLatestFrom(this.store.select(CranesSelectors.calculation)),
      switchMap(([calculationResponse, calculation]) => {
        if (calculation?.construction.uniqueId)
          return (
            this.calculationService
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              .deleteCalculation(
                calculation.construction.uniqueId,
                calculationResponse.fileName,
              )
              .pipe(
                map(() => CranesActions.DeleteCalculationSuccess()),
                catchError(() => of(CranesActions.DeleteCalculationError())),
              )
          );
        else return of(CranesActions.DeleteCalculationError());
      }),
    ),
  );

  deleteConstruction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.DeleteConstruction),
      withLatestFrom(this.store.select(CranesSelectors.calculation)),
      switchMap(([, /*not needed*/ calculation]) => {
        if (calculation?.construction.uniqueId)
          return this.calculationService
            .deleteConstruction(calculation.construction.uniqueId)
            .pipe(
              map(() => CranesActions.DeleteConstructionSuccess()),
              catchError(() => of(CranesActions.DeleteConstructionError())),
            );
        else return of(CranesActions.DeleteConstructionError());
      }),
    ),
  );

  restoreConstruction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CranesActions.RestoreConstruction),
      withLatestFrom(this.store.select(CranesSelectors.calculation)),
      switchMap(([, calculation]) => {
        if (calculation?.construction.uniqueId)
          return this.calculationService
            .restoreConstruction(calculation.construction.uniqueId)
            .pipe(
              map(() => CranesActions.RestoreConstructionSuccess()),
              catchError(() => of(CranesActions.RestoreConstructionError())),
            );
        else return of(CranesActions.RestoreConstructionError());
      }),
    ),
  );

  constructor(
    private pacwebService: PacwebService,
    private ssoService: PacwebSsoService,
    private snackbar: DsSnackbar,
    private translate: TranslateService,
    private calculationService: CalculationService,
    private storageService: StorageService,
    private userService: UserService,
  ) {}
  // tslint:disable-next-line: max-file-line-count
}
