import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@features/auth';
import { Observable } from 'rxjs';

@Injectable()
export class PacwebService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) {}

  public downloadFile(url: string): Observable<Blob> {
    return this.http.request('get', url, { responseType: 'blob' });
  }
}
