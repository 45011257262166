import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { DsPageModule } from '@design-system/feature/page';
import { UserService } from '@features/auth';
import { TranslateModule } from '@ngx-translate/core';
import { FreshdeskWidgetService } from '@paldesk/shared-lib/features/freshdesk-widget';
import { SafePipeModule } from '@shared-lib/safe-pipe';
import { catchError, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { StorageService } from '../shared/generated';

@Component({
  selector: 'pw-help',
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    FlexLayoutModule,
    AsyncPipe,
    SafePipeModule,
    DsPageModule,
    DsPlaceholderModule,
    MatButtonModule,
    TranslateModule,
  ],
  templateUrl: './help.component.html',
  styleUrl: './help.component.scss',
})
export class HelpComponent implements OnInit {
  helpDocumentUrl$: Observable<string | null>;
  errorMessage: string;
  loading = true;

  constructor(
    private _storageService: StorageService,
    private _userService: UserService,
    private _freshdeskService: FreshdeskWidgetService,
  ) {}

  ngOnInit() {
    this.helpDocumentUrl$ = this._userService.currentUser.pipe(
      switchMap(() => {
        this.loading = true;
        return this._storageService.getDocumentation();
      }),
      map((doc) => {
        this.loading = false;
        return doc.downloadUri ?? null;
      }),
      catchError(() => {
        this.loading = false;
        return of(null);
      }),
      shareReplay(1),
    );
  }

  openFreshDeskWidget() {
    this._freshdeskService.openTheWidget(
      [],
      'Pacweb Error Loading Help Document',
    );
  }
}
