@if (helpDocumentUrl$ | async) {
  <iframe
    [src]="helpDocumentUrl$ | async | pdSafe"
    frameborder="0"
    height="1000"
    width="100%"
  ></iframe>
} @else if (loading) {
  <mat-progress-spinner
    [mode]="'indeterminate'"
    color="accent"
    [diameter]="100"
  >
  </mat-progress-spinner>
} @else {
  <ds-placeholder type="error">
    <p>{{ 'pacweb.help.reload_page' | translate }}</p>
    <button mat-stroked-button (click)="openFreshDeskWidget()">
      {{ 'pacweb.help.contact_support' | translate }}
    </button>
  </ds-placeholder>
}
