import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { PdSite } from './site.component';
import {
  PdSiteActions,
  PdSiteBody,
  PdSiteContent,
  PdSiteContentMain,
  PdSiteContentSide,
  PdSiteFab,
  PdSiteHeader,
  PdSiteNavBar,
  PdSiteNavBarItemStyler,
  PdSiteSub,
  PdSiteSubMain,
  PdSiteSubSide,
  PdSiteSubTitleStyler,
  PdSiteTitle,
} from './site.directive';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    MatIconModule,
    MatBadgeModule,
    MatTooltipModule,
  ],
  exports: [
    PdSite,
    PdSiteSub,
    PdSiteSubMain,
    PdSiteSubSide,
    PdSiteContent,
    PdSiteContentSide,
    PdSiteContentMain,
    PdSiteTitle,
    PdSiteActions,
    PdSiteHeader,
    PdSiteBody,
    PdSiteFab,
    PdSiteNavBar,
    PdSiteNavBarItemStyler,
    PdSiteSubTitleStyler,
  ],
  declarations: [
    PdSite,
    PdSiteSub,
    PdSiteSubMain,
    PdSiteSubSide,
    PdSiteContent,
    PdSiteContentSide,
    PdSiteContentMain,
    PdSiteTitle,
    PdSiteActions,
    PdSiteFab,
    PdSiteHeader,
    PdSiteBody,
    PdSiteNavBar,
    PdSiteNavBarItemStyler,
    PdSiteSubTitleStyler,
  ],
})
export class PdSiteModule {
  static forRoot(): ModuleWithProviders<PdSiteModule> {
    return {
      ngModule: PdSiteModule,
      providers: [],
    };
  }
}
