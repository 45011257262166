/* eslint-disable max-lines */
import { createSelector } from '@ngrx/store';
import { PacwebState } from '..';
import { CranesState } from './cranes.reducer';

export namespace CranesSelectors {
  export const cranesState = (state: PacwebState) => state.cranes;

  /**
   * New calculation pop-up
   */
  export const equipments = createSelector(
    cranesState,
    (state: CranesState) => state.equipments,
  );

  export const equipmentIds = createSelector(
    cranesState,
    (state: CranesState) => state.equipmentIds,
  );

  export const equipmentsTableData = createSelector(
    equipments,
    equipmentIds,
    (eqData, eqIds) => {
      if (eqData && eqIds) {
        return eqIds.map((id) => eqData[id]);
      }
    },
  );

  export const helpDocumentUrl = createSelector(
    cranesState,
    (state: CranesState) => state.helpDocumentUrl,
  );

  export const userName = createSelector(
    cranesState,
    (state: CranesState) => state.userName,
  );

  export const selectedEquipmentId = createSelector(
    cranesState,
    (state: CranesState) => state.selectedEquipmentId,
  );

  export const selectedEquipment = createSelector(
    selectedEquipmentId,
    cranesState,
    (id, state) => {
      if (id && state.equipments) return state.equipments[id];
    },
  );

  export const selectedCraneDataStatus = createSelector(
    cranesState,
    (state: CranesState) => state.selectedCraneDataStatus,
  );

  export const equipmentsLoading = createSelector(
    cranesState,
    (state: CranesState) => state.equipmentsLoading,
  );
  export const equipmentsLoadingError = createSelector(
    cranesState,
    (state: CranesState) => state.equipmentsLoadingError,
  );

  /**
   * Existing calculation pop-up
   */
  export const calculationInfos = createSelector(
    cranesState,
    (state: CranesState) => state.calculationInfos,
  );

  export const calculationInfosTotalAmount = createSelector(
    cranesState,
    (state: CranesState) => state.calculationInfosTotalAmount,
  );

  export const calculationInfoIds = createSelector(
    cranesState,
    (state: CranesState) => state.calculationInfoIds,
  );

  export const calculationInfosTableData = createSelector(
    calculationInfos,
    calculationInfoIds,
    (calcInfoData, calcIds) => {
      if (calcInfoData && calcIds) {
        return calcIds.map((id) => calcInfoData[id]);
      }
    },
  );

  export const selectedCalculationId = createSelector(
    cranesState,
    (state: CranesState) => state.selectedCalculationId,
  );

  export const selectedCalculationInfo = createSelector(
    selectedCalculationId,
    cranesState,
    (id, state) => {
      if (id && state.calculationInfos) return state.calculationInfos[id];
    },
  );

  export const calculationInfosLoading = createSelector(
    cranesState,
    (state: CranesState) => state.calculationInfosLoading,
  );
  export const calculationInfosLoadingError = createSelector(
    cranesState,
    (state: CranesState) => state.calculationInfosLoadingError,
  );

  /**
   * Import file pop-up
   */

  export const uploadCalculatioFileLoading = createSelector(
    cranesState,
    (state: CranesState) => state.uploadCalculationFileLoading,
  );
  export const uploadCalculationFileError = createSelector(
    cranesState,
    (state: CranesState) => state.uploadCalculationFileError,
  );

  /**
   * Calculation screen
   */
  export const calculation = createSelector(
    cranesState,
    (state: CranesState) => state.calculation,
  );

  export const calculationLoading = createSelector(
    cranesState,
    (state: CranesState) => state.calculationLoading,
  );

  export const calculationLoadingError = createSelector(
    cranesState,
    (state: CranesState) => state.calculationLoadingError,
  );

  /**
   * Truck
   */
  export const truckManufacturers = createSelector(
    cranesState,
    (state: CranesState) => state.truckManufacturers,
  );

  /**
   * Crane
   */
  export const categories = createSelector(
    cranesState,
    (state: CranesState) => state.categories,
  );

  export const categoriesLoading = createSelector(
    cranesState,
    (state: CranesState) => state.categoriesLoading,
  );

  export const categoriesLoadingError = createSelector(
    cranesState,
    (state: CranesState) => state.categoriesLoadingError,
  );

  export const epsilonCategories = createSelector(
    cranesState,
    (state: CranesState) => state.epsilonCategories,
  );

  export const epsilonCategoriesLoading = createSelector(
    cranesState,
    (state: CranesState) => state.epsilonCategoriesLoading,
  );

  export const epsilonCategoriesLoadingError = createSelector(
    cranesState,
    (state: CranesState) => state.epsilonCategoriesLoadingError,
  );

  export const stabilizers = createSelector(
    cranesState,
    (state: CranesState) => state.stabilizers,
  );

  export const stabilizersLoading = createSelector(
    cranesState,
    (state: CranesState) => state.stabilizersLoading,
  );

  export const stabilizersLoadingError = createSelector(
    cranesState,
    (state: CranesState) => state.stabilizersLoadingError,
  );

  /**
   * Calculation
   */
  export const updateCalculationLoading = createSelector(
    cranesState,
    (state: CranesState) => state.updateCalculationLoading,
  );

  export const updateCalculationLoadingError = createSelector(
    cranesState,
    (state: CranesState) => state.updateCalculationLoadingError,
  );

  export const validateSSOLoading = createSelector(
    cranesState,
    (state: CranesState) => state.validateSSOLoading,
  );

  export const validateSSOLoadingError = createSelector(
    cranesState,
    (state: CranesState) => state.validateSSOLoadingError,
  );

  export const validateSSOResponse = createSelector(
    cranesState,
    (state: CranesState) => state.validateSSOResponse,
  );

  export const errorMessage = createSelector(
    cranesState,
    (state: CranesState) => state.errorMessage,
  );

  export const orderSSOLoading = createSelector(
    cranesState,
    (state: CranesState) => state.orderSSOLoading,
  );

  export const orderSSOLoadingError = createSelector(
    cranesState,
    (state: CranesState) => state.orderSSOLoadingError,
  );

  export const orderSSOResponse = createSelector(
    cranesState,
    (state: CranesState) => state.orderSSOResponse,
  );

  export const highlighted = createSelector(
    cranesState,
    (state: CranesState) => state.highlighted,
  );

  export const overrideStabilizerCoordinates = createSelector(
    cranesState,
    (state: CranesState) => {
      if (state.calculation) {
        return state.calculation.construction.crane
          .overrideStabilizerCoordinates;
      }
    },
  );

  export const dirty = createSelector(
    cranesState,
    (state: CranesState) => state.dirty,
  );

  export const uploadPrintTrigger = createSelector(
    cranesState,
    (state: CranesState) => state.uploadPrintTrigger,
  );

  export const calculationResponse = createSelector(
    cranesState,
    (state: CranesState) => state.calculationResponse,
  );

  export const resultCalculationError = createSelector(
    cranesState,
    (state: CranesState) => state.resultCalculationError,
  );

  export const resultCalculationLoading = createSelector(
    cranesState,
    (state: CranesState) => state.resultCalculationLoading,
  );

  export const calculationRequest = createSelector(
    cranesState,
    (state: CranesState) => state.calculationRequest,
  );

  export const savePending = createSelector(
    cranesState,
    (state: CranesState) => state.savePending,
  );

  export const saveError = createSelector(
    cranesState,
    (state: CranesState) => state.saveError,
  );

  /**
   * Archive
   */

  export const archive = createSelector(
    cranesState,
    (state: CranesState) => state.archive,
  );

  export const archiveLoading = createSelector(
    cranesState,
    (state: CranesState) => state.archiveLoading,
  );

  export const archiveLoadingError = createSelector(
    cranesState,
    (state: CranesState) => state.archiveLoadingError,
  );

  export const isPowerUser = createSelector(
    cranesState,
    (state: CranesState) => state.isPowerUser,
  );

  export const isDeleteUser = createSelector(
    cranesState,
    (state: CranesState) => state.isDeleteUser,
  );

  export const hasLoad = createSelector(
    cranesState,
    (state: CranesState) => state.hasLoad,
  );

  export const calculationValid = createSelector(
    cranesState,
    (state: CranesState) =>
      state.truckValid &&
      state.craneValid &&
      state.truckMeasurementsValid &&
      state.epsilonValid &&
      state.frontStabilizersValid &&
      state.rearStabilizersValid &&
      state.craneStabilizersValid,
  );

  export const truckValid = createSelector(
    cranesState,
    (state: CranesState) => state.truckValid,
  );

  export const craneValid = createSelector(
    cranesState,
    (state: CranesState) => state.craneValid,
  );

  export const truckMeasurementsValid = createSelector(
    cranesState,
    (state: CranesState) => state.truckMeasurementsValid,
  );

  export const epsilonValid = createSelector(
    cranesState,
    (state: CranesState) => state.epsilonValid,
  );

  export const frontStabilizersValid = createSelector(
    cranesState,
    (state: CranesState) => state.frontStabilizersValid,
  );

  export const rearStabilizersValid = createSelector(
    cranesState,
    (state: CranesState) => state.rearStabilizersValid,
  );

  export const craneStabilizersValid = createSelector(
    cranesState,
    (state: CranesState) => state.craneStabilizersValid,
  );

  export const termsAlreadyShown = createSelector(
    cranesState,
    (state: CranesState) => state.termsAlreadyShown,
  );

  export const deleteCalculationLoading = createSelector(
    cranesState,
    (state: CranesState) => state.deleteCalculationLoading,
  );

  export const deleteCalculationError = createSelector(
    cranesState,
    (state: CranesState) => state.deleteCalculationError,
  );

  export const deleteConstructionLoading = createSelector(
    cranesState,
    (state: CranesState) => state.deleteConstructionLoading,
  );

  export const deleteConstructionError = createSelector(
    cranesState,
    (state: CranesState) => state.deleteConstructionError,
  );

  export const showStabilizers = createSelector(
    cranesState,
    (state: CranesState) => state.showStabilizers,
  );

  export const isRestoreConstructionLoading = createSelector(
    cranesState,
    (state: CranesState) => state.isRestoreConstructionLoading,
  );

  export const restoreConstructionHasFailed = createSelector(
    cranesState,
    (state: CranesState) => state.restoreConstructionHasFailed,
  );
}
